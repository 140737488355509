import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    width: 100%;

    .box-content {
        position: relative;
        min-height: 20em;
        text-align: center;
        background-color: white;
        box-shadow: 0px .6em 1em #484a521a;
        border-radius: 1em;
        padding: 2em;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .top-left-icon {
            position: absolute;
            top: -4%;
            left: 5%;
            transform: translateX(-4%);
        }

        .stars-container {
            display: flex;
            margin: 1em 0;
        }

        .top-center-icon {
            margin: 1em 0;
        }

        .description {
            font-size: 14px;
            color: #484A52;
        }

        .author {
            font-size: .9em;
            margin-top: 1.5em;
            font-weight: 700;
            color: #484A52;
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .box-content {
            .stars-container {
                margin-top: 2em;
            }

            .description {
                color: #484A52;
            }
        }
    }
`;
