import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    z-index: 2;

    .headline {
        font-size: 1.7em;
        font-weight: 700;
        color: #262626;
        text-align: center;
        margin-bottom: 3em;
    }

    .list {
        margin-top: 3em;
        padding-bottom: 3em;
    }

    @media all and (max-width: ${variables.tabletL}) {
        height: 560px;
    }

    @media all and (max-width: ${variables.mobileL}) {
        height: 500px;

        .layout-container {
            width: 100%;

            .headline {
                padding: 0 5%;
            }
        }

        .list {
            display: none !important;
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        overflow-x: visible;
        height: auto;
    }
`;

