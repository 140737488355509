import { FunctionComponent } from 'react';

import { shorten } from 'utils/string';

import StyledComponent from './styles';
import { Props } from './types';

const getStars = (rating: number) => {
    const ratings = new Array(Math.floor(rating)).fill(1);

    if (ratings.length !== Math.ceil(rating)) {
        const lastIndex = ratings.length - 1;

        ratings[lastIndex] = 0.5;
    }

    const ratingsAmount = ratings.length;
    const starsAmount = 5;
    const missingElementsAmount = starsAmount - ratingsAmount;

    if (missingElementsAmount > 0) {
        const arrayFill = new Array(missingElementsAmount).fill(0);

        ratings.push(...arrayFill);
    }

    return ratings.map((rating, ratingIndex) => {
        switch (rating) {
            default:
                return (
                    <img
                        key={ratingIndex}
                        src="/theme/images/testimonials/star-empty.svg"
                        alt="Gwiazdka opinii"
                        width="22px"
                        height="22px"
                        loading="lazy"
                    />
                );
            case 0.5:
                return (
                    <img
                        key={ratingIndex}
                        src="/theme/images/testimonials/star-half.svg"
                        alt="Gwiazdka opinii"
                        width="22px"
                        height="22px"
                        loading="lazy"
                    />
                );
            case 1:
                return (
                    <img
                        key={ratingIndex}
                        src="/theme/images/testimonials/star.svg"
                        alt="Gwiazdka opinii"
                        width="22px"
                        height="22px"
                        loading="lazy"
                    />
                );
        }
    });
};

const PublicLayoutOpinionlBox: FunctionComponent<Props> = ({ rating, content, author }) => ((
    <StyledComponent className="public-layout-opinion-box">
        <div className="box-content">
            <img
                className="top-left-icon"
                src="/theme/images/testimonials/quotes.svg"
                loading="lazy"
                alt="Opinia"
            />
            <div className='stars-container'>
                {getStars(rating)}
            </div>
            <div
                className="description"
                dangerouslySetInnerHTML={{ __html: shorten(content, 300, true) }}
            />
            <div
                className="author"
                dangerouslySetInnerHTML={{ __html: author }}
            />
        </div>
    </StyledComponent>
));

export default PublicLayoutOpinionlBox;