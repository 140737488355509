import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';

import Opinion from 'models/Opinion';

import { list as listOpinions } from 'requests/opinions';

import DeviceContext from 'components/context/Device';
import LayoutContainer from 'components/layout/LayoutContainer';
import OpinionBox from 'components/modules/public/layout/boxes/OpinionBox';

import StyledComponent from './styles';
import { Props } from './types';

const Slick = dynamic(() => import('components/layout/Slick'));
const MobileSlider = dynamic(() => import('components/layout/MobileSlider'));

const PublicLayoutOpinions: FunctionComponent<Props> = ({ opinions, headline }) => {
    const [inactiveShowSliderInteraction, setInactiveShowSliderInteraction] = useState<boolean>(false);
    const deviceContext = useContext(DeviceContext);
    const [opinionsList, setOpinionsList] = useState<Opinion[]>(opinions);

    const containerRef = useRef<number>(null);
    const list = opinionsList || [];

    const touchStart = (e) => containerRef.current = e.touches[0].clientX;

    const touchMove = (e) => {
        if (deviceContext?.isMobile) return;
        if (inactiveShowSliderInteraction) return;

        const clientX = e.touches[0].clientX;

        function getDifference(a: number, b: number) {
            return Math.abs(a - b);
        }

        const difference = getDifference(clientX, containerRef.current);

        if (difference > 80) return setInactiveShowSliderInteraction(true);
    };

    const settings = {
        arrows: false,
        dots: true,
        slidesToShow: list.length > 2 ? 3 : list.length,
        slidesToScroll: list.length > 2 ? 3 : 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    useEffect(() => {
        if (!opinions) {
            listOpinions({
                page: 1,
                perPage: 3,
            })
                .then(response => setOpinionsList(response.elements
                    .map(element => new Opinion(element))
                ));
        }
    }, []);

    return (
        <StyledComponent className="public-layout-opinions">
            <LayoutContainer>
                <div
                    className="headline"
                    dangerouslySetInnerHTML={{ __html: headline }}
                />
                {!deviceContext?.isMobile && (
                    <div
                        className="list"
                        onTouchStart={touchStart}
                        onTouchMove={touchMove}
                    >
                        {Array.isArray(list) && list.length > 0 && (
                            <Slick sliderProps={settings}>
                                {list.map(listElem => (
                                    <OpinionBox
                                        key={listElem.id}
                                        {...listElem}
                                    />
                                ))}
                            </Slick>
                        )}
                    </div>
                )}

                {deviceContext?.isMobile && (
                    <>
                        {Array.isArray(list) && list.length > 0 && (
                            <MobileSlider>
                                {list.map(listElem => (
                                    <OpinionBox
                                        key={listElem.id}
                                        {...listElem}
                                    />
                                ))}
                            </MobileSlider>
                        )}
                    </>
                )}
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicLayoutOpinions;
