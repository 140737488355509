import { FunctionComponent } from 'react';

import SectionOpinions from 'components/modules/public/layout/sections/Opinions';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionOpinionsVariantDefault: FunctionComponent<Props> = ({ translations, ...props }) => {
    return (
        <StyledComponent className="public-section-opinions-variant-default">
            <SectionOpinions
                {...translations}
                {...props}
            />
        </StyledComponent>
    );
};

export default PublicSectionOpinionsVariantDefault;
